<template>
  <div>
    <transition name="fade-2" mode="out-in" appear>
      <div
        class="wise-popup__container wise-popup__container--mini hello-popup"
        v-if="showHelloSign">
        <div class="wise-popup">
          <section class="wise-popup__body">
            <div id="hello-sign-container" />
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import HelloSign from 'hellosign-embedded';
import { mapActions, mapGetters } from 'vuex';
import { getEnv } from '@/utils/env';
import loginMixins from '@m/user/mixins/login';
export default {
  name: 'HelloSign',
  mixins: [loginMixins],
  props: {
    businessId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modals: {
        certify: false
      },
      showHelloSign: false,
      helloSignClient: null,
      helloSignTryCount: 0
    };
  },
  computed: {
    ...mapGetters('kyb', ['getAgreementUrlAndStatus'])
  },
  created() {
    /** @type {import('@m/kyb/store/state').kybState['agreementUrlAndStatus']} */
    const agreementUrlAndStatus = this.getAgreementUrlAndStatus;
    if (agreementUrlAndStatus) {
      this.openHelloSign(agreementUrlAndStatus);
    } else {
      this.getHelloSignURL();
    }
  },
  methods: {
    ...mapActions('kyb', ['fetchHelloSignURL']),
    /**
     * @param {import('@m/kyb/store/state').kybState['agreementUrlAndStatus']} helloSignData
     */
    openHelloSign(helloSignData) {
      const that = this;

      if (helloSignData != null && helloSignData.url.length > 0) {
        this.showHelloSign = true;
        this.modals.certify = true;

        setTimeout(() => {
          this.helloSignClient = new HelloSign({
            clientId: getEnv('VUE_APP_HELLO_SIGN_CLIENT_ID')
          });
         
          this.helloSignClient.open(helloSignData.url, {
            skipDomainVerification: false,
            allowCancel: true,
            container: document.querySelector('#hello-sign-container'),
            whiteLabeling: {
              primary_button_color: '#FA5555',
              secondary_button_text_color: '#FA5555'
            }
          });

          this.helloSignClient.on(HelloSign.events.READY, (data) => {
            console.log(data);
          });

          this.helloSignClient.on(HelloSign.events.DECLINE, (data) => {
            console.log(data);
          });

          this.helloSignClient.on(HelloSign.events.SIGN, (data) => {
            console.log('The signature request was signed!', data);

            that.showHelloSign = false;
            that.helloSignClient.close();
            that.$emit('close', null);
            this.submitApplication();
          });

          this.helloSignClient.on(HelloSign.events.CANCEL, () => {
            console.log('The signature request was canceled!');
            that.showHelloSign = false;
            that.helloSignClient.close();
            that.$emit('close', null);
            that.getHelloSignURL();
          });

          this.helloSignClient.on(HelloSign.events.ERROR, (data) => {
            console.log(
              `Something went wrong! Please try again. Error code: ${data.code}`
            );
            that.showHelloSign = false;
            that.helloSignClient.close();
            that.$emit('close', null);
            that.getHelloSignURL();
          });

          this.helloSignClient.on(HelloSign.events.CLOSE, () => {
            console.log('The signature request was close!');
            that.showHelloSign = false;
            that.helloSignClient.close();
            that.$emit('close', null);
          });
        }, 300);
      } else {
        console.log('if condition wrong : ' + helloSignData);
      }
    },
    submitApplication() {
      this.$emit('submit', this.businessId);
    },
    getHelloSignURL() {
      if (this.helloSignTryCount == 2) {
        this.apiErrorMessage(
          'There is problem loading hellosign please try after sometime.'
        );
        this.logoutUser();
        return;
      } else {
        this.helloSignTryCount = this.helloSignTryCount + 1;
        const loader = this.showLoader();
        this.fetchHelloSignURL(this.businessId)
          .then((res) => {
            /** @type {import('@m/kyb/store/state').kybState['agreementUrlAndStatus']} */
            const agreementUrlAndStatus = res;
            if (agreementUrlAndStatus) {
              this.openHelloSign(agreementUrlAndStatus);
            }
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// Hello sign styles
.hello-popup {
  overflow: hidden;

  .wise-popup {
    margin: auto;
    width: 100%;
    max-width: 720px;
    min-height: 70vh;
    max-height: 90vh;
  }
}

#hello-sign-container {
  height: 70vh;
}
</style>
