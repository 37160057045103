<template>
  <div>
    <div class="kyb-helloSign">
      <div
        class="widgetContainer widgetContainer--center bg-white appStatus appStatus--review kyb-helloSign__agreement"
        v-if="
          getSelectedBusiness.kyb.status === 'approved' &&
            getSelectedBusiness.disclosureStatus === 'pending' &&
            isSignSubmitted &&
            isLoading
        ">
        <div class="widgetContainer__body">
          <LottieAnimation animation-name="searching" reference="hello-sign-1" :loop="true" />
          <p class="title">
            {{ $t('helloSignStatus_waiting_title') }}
          </p>
          <div class="widgetContainer__footer--fixed" /> <!-- Keeps alignment -->
        </div>
      </div>

      <div
        class="
          widgetContainer widgetContainer--center widgetContainer--full-screen
          appStatus appStatus--review
        "
        v-else-if="showHelloSign === false">
        <div class="widgetContainer__body">
          <LottieAnimation animation-name="searching" reference="hello-sign-2" :loop="true" />
          <p class="title">
            {{ $t('helloSignStatus_inReview_title') }}
          </p>
          <p class="description">
            {{ $t('kybStatus_inReview_description') }}
          </p>
        </div>
        <div class="widgetContainer__footer--fixed">
          <el-button
            type="primary"
            class="welcome__get-started el-button__brand brand"
            @click="openHelp">
            {{ $t('kybStatus_inReview_buttonTitle') }}
          </el-button>
        </div>
      </div>
    </div>
    <HelloSign
      @close="showHelloSign = false"
      @submit="submit"
      v-if="getSelectedBusiness && showHelloSign"
      :business-id="getSelectedBusiness.id" />
  </div>
</template>
<script>
import HelloSign from '@m/kyb/components/HelloSign';
import businessMixin from '../mixins/business';
import { mapActions, mapMutations } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  name: 'HelloSignView',
  components: {
    HelloSign,
    LottieAnimation
  },
  mixins: [businessMixin],
  data() {
    return {
      retryCount: 0,
      showHelloSign: false,
      isSignSubmitted: false,
      isLoading: false
    };
  },
  created() {
    if (
      !this.getSelectedBusiness ||
      this.getSelectedBusiness.kyb.status !== 'approved'
    ) {
      this.$router.push('/kyb/businessStatus');
    } else {
      this.showHelloSign = true;
    }
  },
  methods: {
    ...mapActions('kyb', ['fetchBusiness', 'listAllBusiness']),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    ...mapMutations('account', ['updateCreateAccountData']),
    /**
     * @param {string} businessId
     */
    fetchKyb(businessId) {
      const loader = this.showLoader();
      this.fetchBusiness(businessId)
        .then((data) => {
          /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType']} */
          const businessData = data;
          this.setSelectedBusiness(businessData);
          if (
            businessData.disclosureStatus === 'pending' &&
            this.isSignSubmitted
          ) {
            if (this.retryCount < 5) {
              this.isLoading = true;
              this.retryCount++;
              setTimeout(() => {
                this.fetchKyb(businessId);
              }, 5000);
            } else {
              this.isLoading = false;
            }
          } else {
            if (
              businessData &&
              businessData.kyb.status === 'approved' &&
              !['soleProprietor', 'singleMemberLLC'].includes(
                businessData.entityType
              )
            ) {
              this.$router.push('/kyb/ownership');
            }
            if (
              businessData &&
              businessData.kyb.status === 'approved' &&
              businessData.disclosureStatus === 'completed'
            ) {
              this.updateCreateAccountData({
                businessId: this.getSelectedBusiness.id,
                type: 'businessChecking'
              });
              //go to account page
              this.$router.push('/account/setup');
            }
            this.isLoading = false;
          }
        })
        .catch((e) => {
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    submit() {
      this.isSignSubmitted = true;
      this.retryCount = 0;
      this.showHelloSign = false;
      this.fetchKyb(this.getSelectedBusiness.id);
    },
  }
};
</script>
<style lang="scss">
  .kyb-helloSign {
    &__agreement {
      .title {
        padding-bottom: 100px;
      }
    }
  }
</style>